import React, {  useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import YouTube from 'react-youtube';
import {  listFiles,  } from '../actions';
import {  useDispatch, useSelector} from 'react-redux';
import ReactPlayer from 'react-player';
import { Loader } from '../components';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  Typography,
  makeStyles,
  ButtonGroup ,
  Button
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  card: {
    // width: '100%',
    borderRadius: 6,
  },
  accordionDetails: {
    flexDirection: 'column',
  },
}));
const LibraryVideos = () => {
  const classes = useStyles();

  const fileList = useSelector((state) => state.fileList);
  const { files, loading, error } = fileList;
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState({}); // Tracks current page for each category
  const itemsPerPage = 5; // Number of items per page

  useEffect(() => {
    dispatch(listFiles('library', ['Training', 'Sales & Marketing']));
  }, [dispatch]);

  const groupVideosByCategory = (videos) => {
    const grouped = {};
  
    videos.forEach((video) => {
      const parts = video.key.split('/'); // Split key by "/"
      const category = parts[0]; // Main category
      const subcategory = parts[1]; // Subcategory (if any)
  
      if (!grouped[category]) {
        grouped[category] = {}; // Initialize main category
      }
  
      if (subcategory) {
        if (!grouped[category][subcategory]) {
          grouped[category][subcategory] = []; // Initialize subcategory
        }
        grouped[category][subcategory].push(video); // Add video to subcategory
      } 

    });
  
    return grouped;
  };

  const groupedVideos = groupVideosByCategory(files.videos || []);

  const handlePageChange = (category, subcategory, direction) => {
    setCurrentPage((prevState) => ({
      ...prevState,
      [category]: {
        ...prevState[category],
        [subcategory]: (prevState[category]?.[subcategory] || 1) + direction,
      },
    }));
  };

  return (
    <>
        <div>
        {Object.entries(groupedVideos).map(([category, subcategories]) => (
          <div>
      <Typography gutterBottom variant="h6" color="textPrimary" style={{ marginTop: '15px' }}>
      {category}
      </Typography>
      {Object.entries(subcategories).map(([subcategory, videos]) => {
                  const currentCategoryPage = currentPage[category]?.[subcategory] || 1;
                  const totalPages = Math.ceil(videos.length / itemsPerPage);
                  const paginatedVideos = videos.slice(
                    (currentCategoryPage - 1) * itemsPerPage,
                    currentCategoryPage * itemsPerPage
                  );

                  return (
                    <Accordion key={subcategory} style={{ marginTop: 15 }}>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls={`${subcategory.toLowerCase()}-content`}
                        id={`${subcategory.toLowerCase()}-header`}
                      >
                        <Typography>{subcategory}</Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ display: 'block' }}>
                        {paginatedVideos.map((video) => (
                          <LazyLoad key={video.id} height={200} offset={100}>
                            <div style={{ marginBottom: '15px' }}>
                              <Typography variant="subtitle1" gutterBottom>
                                {video.name}
                              </Typography>
                              <ReactPlayer
                                url={video.signedUrl}
                                controls
                                width="100%"
                                height="300px"
                              />
                            </div>
                          </LazyLoad>
                        ))}
                        {/* Pagination Controls */}
                        <ButtonGroup
                          style={{
                            marginTop: '15px',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Button
                            onClick={() => handlePageChange(category, subcategory, -1)}
                            disabled={currentCategoryPage === 1}
                          >
                            Previous
                          </Button>
                          <Button disabled>
                            Page {currentCategoryPage} of {totalPages}
                          </Button>
                          <Button
                            onClick={() => handlePageChange(category, subcategory, 1)}
                            disabled={currentCategoryPage === totalPages}
                          >
                            Next
                          </Button>
                        </ButtonGroup>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
          </div>
        ))}
      </div>
    </>
  );
};

export { LibraryVideos };
